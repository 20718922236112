import React from 'react'
import { BaseComponent } from '../../components/HOC/BaseComponent'
import World from '../../components/World'

class Worldvious extends BaseComponent
{
    constructor(props)
    {
        super(props);

        this.state = {
            clients: []
        }

    }

    componentDidMount() {
        this.subscribeToSharedState('worldvious_users', (value) => {
            let clients = [];
            if (value) {
                if (value.clients) {
                    clients = value.clients;
                }
            }
            this.setState({
                clients: clients
            });
        });
    }

    render() {
        return <>
            <World clients={this.state.clients} />
        </>
    }
}

export default Worldvious
