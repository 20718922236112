import _ from 'the-lodash'
import React, { useState, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import Layout from '../../components/Layout'
import { Map as LeafletMap, Marker, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import L from 'leaflet'
import chroma from 'chroma-js'
import './styles.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import Kubernetes from '../../icons/Kubernetes'
import { Helmet } from 'react-helmet'


const World = ({ clients }) => {
    const [view, setView] = useState('node')
    const [redraw, setRedraw] = useState(null)

    const mappedClients = clients.map(client => {
        const counters = client.counters || [];
        const countersDict = _.makeDict(counters, x => x.name, x => x.count);

        return {
            product: client.product,
            version: client.version,
            location: client.location,
            counters: countersDict
        }
    })

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        document.addEventListener('gesturestart', function(e) {
            e.preventDefault();
            document.body.style.zoom = 0.99;
        });

        document.addEventListener('gesturechange', function(e) {
            e.preventDefault();
            document.body.style.zoom = 0.99;
        });

        document.addEventListener('gestureend', function(e) {
            e.preventDefault();
            document.body.style.zoom = 0.99;
        });

        document.addEventListener('touchmove', function (e) {
            e.preventDefault();
        });

        return () => document.body.style.removeProperty('overflow')
    }, [])

    const getCountersRange = () => {
        if (view === 'cluster') {
            return [1, 10]
        }
        const range = mappedClients.reduce(
            (acc, client) => {
                const selectedCounterValue = client.counters[view];
                const curentValue = selectedCounterValue ? selectedCounterValue : 0;
                if (!curentValue) return [acc[0], acc[1]]
                return [
                    Math.min(acc[0], curentValue),
                    Math.max(acc[1], curentValue),
                ]
            },
            [Infinity, 0]
        )
        if (range[0] == Infinity) {
            range[0] = 0
        }
        return range
    }

    const calculateChroma = (progress) => {
        return chroma.scale(['#6d0aed', '#ff285e'])(progress)
    }

    const handleSelectionCollapse = (e) => {
        e.currentTarget.parentNode.classList.toggle('collapsed')
    }

    const createIcon = (value) => {
        let currentColor = view === 'cluster' ? '#326DE6' : '#ccc'
        const [min, max] = getCountersRange()
        if (max - min == 0) {
            currentColor = calculateChroma(0)
        } else if (value) {
            const counterPerc = Math.abs(value - min) / (max - min)
            currentColor = calculateChroma(counterPerc)
        }

        return L.divIcon({
            className: 'custom-icon',
            html: ReactDOMServer.renderToString(
                <Kubernetes fillColor={currentColor} />
            ),
        })
    }

    const createClusterValuesCount = (val) => {
        let sumOfValues = 0;
        const selected = detectView()

        if (view !== 'cluster') {
            const arrayOfValues = val
                .getAllChildMarkers()
                .map((el) =>
                    el._popup.options.children[1].props.children[selected]
                        ? el._popup.options.children[1].props.children[selected]
                              .props.children[1]
                        : 0
                )
                .filter(x => _.isNumber(x))

            if (arrayOfValues.length > 0) {
                sumOfValues = arrayOfValues
                    .reduce((acc, el) => acc + el)
            }
        } else {
            sumOfValues = val.getChildCount ? val.getChildCount() : 0
        }

        setRedraw(false)
        return renderClusterIcon(sumOfValues)
    }

    const renderClusterIcon = (sumOfValues) => {
        return L.divIcon({
            html: '<div><span>' + sumOfValues + '</span></div>',
            className:
                'leaflet-marker-icon marker-cluster marker-cluster-small',
            iconSize: L.point(40, 40, true),
            backgroundColor: 'white',
        })
    }

    const detectView = () => {
        switch (view) {
            case 'node':
                return 0

            case 'pod':
                return 1

            case 'cluster':
                return null

            default:
                return 0
        }
    }

    const switchSelection = (value) => {
        setView(value)
        setRedraw(true)
    }

    const createMapBounds = () => {
        const southwest = L.latLng(-90, -180)
        const northeast = L.latLng(90, 180)
        const bounds = L.latLngBounds(southwest, northeast)
        return bounds
    }

    const makePopupCounter = (value) => {
        if (_.isNullOrUndefined(value)) {
            return '?'; //0
        }
        return value;
    }

    if (typeof window !== 'undefined') {
        return (
            <>
                <Helmet>
                    <link
                        rel="stylesheet"
                        href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
                        integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
                        crossOrigin=""
                    />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover, user-scalable=no, shrink-to-fit=no" />
                    <meta name="HandheldFriendly" content="true"></meta>
                </Helmet>
                <Layout
                    sectionName="world"
                    seoTitle="Worldvious"
                    seoDescription="Worldvious map"
                    hideFooter
                >
                    <div className="map-container">
                        <LeafletMap
                            center={[40, 0]}
                            zoom={3}
                            minZoom={3}
                            maxBounds={createMapBounds()}
                            maxBoundsViscosity={1}
                            tap={false}
                        >
                            <ReactLeafletGoogleLayer
                                googleMapsLoaderConf={{
                                    KEY: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
                                }}
                                noWrap={true}
                            />
                            <MarkerClusterGroup
                                showCoverageOnHover={false}
                                spiderfyDistanceMultiplier={2}
                                iconCreateFunction={(v) =>
                                    createClusterValuesCount(v)
                                }
                                key={redraw ? 'force-redraw' : ''}
                            >
                                {mappedClients.map((client, index) => (
                                    <Marker
                                        position={[
                                            client.location.lat,
                                            client.location.long,
                                        ]}
                                        icon={createIcon(
                                            client.counters[view]
                                        )}
                                        key={index}
                                    >
                                        <Popup key={index}>
                                            <div>
                                                {client.product}{' '}
                                                {client.version &&
                                                    `(${client.version})`}
                                            </div>
                                            {(
                                                <>
                                                    <div>
                                                        {'nodes: '}
                                                        {makePopupCounter(client.counters['node'])}
                                                    </div>
                                                    <div>
                                                        {'pods: '}
                                                        {makePopupCounter(client.counters['pod'])}
                                                    </div>
                                                </>
                                            )}
                                        </Popup>
                                    </Marker>
                                ))}
                            </MarkerClusterGroup>
                        </LeafletMap>

                        <div className="selection-box">
                            <p onClick={handleSelectionCollapse} className="selection-title">Kubevious Users Around the World</p>
                            <span>Count: </span>
                            <label>
                                <input
                                    name="selection"
                                    type="radio"
                                    value="cluster"
                                    checked={view === 'cluster'}
                                    onChange={() => switchSelection('cluster')}
                                />{' '}
                                Clusters
                            </label>
                            <label>
                                <input
                                    name="selection"
                                    type="radio"
                                    value="node"
                                    checked={view === 'node'}
                                    onChange={() => switchSelection('node')}
                                />{' '}
                                Nodes
                            </label>
                            <label>
                                <input
                                    name="selection"
                                    type="radio"
                                    value="pod"
                                    checked={view === 'pod'}
                                    onChange={() => switchSelection('pod')}
                                />{' '}
                                Pods
                            </label>
                            <div className="visualization">
                                <span>{getCountersRange()[0]}</span>
                                <div
                                    className="color-bar"
                                    style={{
                                        background: `linear-gradient(90deg, ${calculateChroma(
                                            0.0
                                        )}, ${calculateChroma(1.0)})`,
                                    }}
                                ></div>
                                <span>{getCountersRange()[1]}</span>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        )
    }
    return <h3>Loading...</h3>
}

export default World
